import { AssetType, Asset } from '@commonstock/common/src/types'
import { DateTime } from 'luxon'

export function isNumber(maybeNumber: number | undefined | null): maybeNumber is number {
  return typeof maybeNumber === 'number' && !isNaN(maybeNumber)
}

export const ValueMissing = '-/-'

const currencyOptions: Intl.NumberFormatOptions = { style: 'currency', currency: 'USD' }
const percentOptions = { style: 'percent' }
const signedOptions = { signDisplay: 'exceptZero' }
const intOptions = { minimumFractionDigits: 0, maximumFractionDigits: 0 }
const floatOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
const floatSmallOptions = { minimumSignificantDigits: 1, maximumSignificantDigits: 2 }
const floatMiniOptions = { minimumFractionDigits: 1, maximumFractionDigits: 1 }

const intFmt = new Intl.NumberFormat('en', intOptions)
export function formatInt(number: number | undefined | null): string | null {
  return !isNumber(number) ? null : intFmt.format(number)
}

const floatFmt = new Intl.NumberFormat('en', floatOptions)
const floatSmallFmt = new Intl.NumberFormat('en', floatSmallOptions)
export function formatDecimal(number: number | undefined | null): string | null {
  // https://stackoverflow.com/a/14428340
  return !isNumber(number)
    ? null
    : number > -0.01 && number < 0.01 && number !== 0
    ? floatSmallFmt.format(number)
    : floatFmt.format(number)
}

const floatSignedFmt = new Intl.NumberFormat('en', { ...floatOptions, ...signedOptions })
const floatSignedSmallFmt = new Intl.NumberFormat('en', { ...floatSmallOptions, ...signedOptions })
export function formatDecimalSigned(number: number | undefined | null): string | null {
  // https://stackoverflow.com/a/14428340
  return !isNumber(number)
    ? null
    : number > -0.01 && number < 0.01 && number !== 0
    ? floatSignedSmallFmt.format(number)
    : floatSignedFmt.format(number)
}

const THOUSAND = 1000
const MILLION = 1000000
const BILLION = 1000000000

const largeNumberFmt = new Intl.NumberFormat('en', { minimumFractionDigits: 0, maximumFractionDigits: 1 })
// @NOTE: Avoid compact notation due to lack of safari support
export function formatLargeNumbers(number: number) {
  if (!isNumber(number)) return null
  const value = Math.round(number)
  if (value >= BILLION) return `${largeNumberFmt.format(value / BILLION)}B`
  if (value >= MILLION) return `${largeNumberFmt.format(value / MILLION)}M`
  if (value >= THOUSAND) return `${largeNumberFmt.format(value / THOUSAND)}k`
  return `${value}`
}

const currencySignificantFmt = new Intl.NumberFormat('en', { ...floatOptions, ...currencyOptions })
const currencySignificantSmallFmt = new Intl.NumberFormat('en', { ...floatSmallOptions, ...currencyOptions })
export function formatMoney(number: number | undefined | null): string | null {
  // https://stackoverflow.com/a/14428340
  return !isNumber(number)
    ? null
    : number > -0.01 && number < 0.01 && number !== 0
    ? currencySignificantSmallFmt.format(number)
    : currencySignificantFmt.format(number)
}

const currencySignificantSignedFmt = new Intl.NumberFormat('en', {
  ...floatOptions,
  ...currencyOptions,
  ...signedOptions
})
const currencySignificantSignedSmallFmt = new Intl.NumberFormat('en', {
  ...floatSmallOptions,
  ...currencyOptions,
  ...signedOptions
})
export function formatMoneySigned(number: number | undefined | null): string | null {
  // https://stackoverflow.com/a/14428340
  return !isNumber(number)
    ? null
    : number > -0.01 && number < 0.01 && number !== 0
    ? currencySignificantSignedSmallFmt.format(number)
    : currencySignificantSignedFmt.format(number)
}

// @NOTE: Avoid compact notation due to lack of safari support
export function formatLargeMoney(amount: number) {
  const value = formatLargeNumbers(amount)
  return `$${value}`
}

const percentFmt = new Intl.NumberFormat('en', { ...floatOptions, ...percentOptions })
const percentSmallFmt = new Intl.NumberFormat('en', { ...floatSmallOptions, ...percentOptions })
const percentMiniFmt = new Intl.NumberFormat('en', { ...floatMiniOptions, ...percentOptions })
const percentSignedFmt = new Intl.NumberFormat('en', { ...floatOptions, ...percentOptions, ...signedOptions })
const percentSignedSmallFmt = new Intl.NumberFormat('en', {
  ...floatSmallOptions,
  ...percentOptions,
  ...signedOptions
})
const percentSignedMiniFmt = new Intl.NumberFormat('en', {
  ...floatMiniOptions,
  ...percentOptions,
  ...signedOptions
})
/**
 * takes a number and returns a formatted percent string.
 * @param number where the number is already in percent units. eg: 50 -> 50%
 */
export function formatPercent(
  number: number | undefined | null,
  signed?: boolean,
  mini?: boolean,
  trailingZero?: boolean
): string | null {
  if (!isNumber(number)) return null
  number = number / 100
  if (mini) return (signed ? percentSignedMiniFmt : percentMiniFmt).format(number)
  return number > -0.01 && number < 0.01 && number !== 0 && !trailingZero
    ? (signed ? percentSignedSmallFmt : percentSmallFmt).format(number)
    : (signed ? percentSignedFmt : percentFmt).format(number)
}
export function formatPercentSigned(number: number | undefined | null): string | null {
  return formatPercent(number, true)
}
export function formatPercentMini(number: number | undefined | null): string | null {
  return formatPercent(number, undefined, true)
}
export function formatPercentSignedMini(number: number | undefined | null): string | null {
  return formatPercent(number, true, true)
}
export function formatPercentSignedTrailing(number: number | undefined | null): string | null {
  return formatPercent(number, true, undefined, true)
}

export function decimalAndNumbersOnly(value: string) {
  return (value ?? '').replace(/[^0-9.]/g, '')
}

export function stringToNumber(str: string) {
  return parseFloat(decimalAndNumbersOnly(str))
}

function formatSymbol(asset: Asset): string
function formatSymbol(symbol: string, type: AssetType | string): string
function formatSymbol(assetOrSymbol: Asset | string, type?: AssetType | string) {
  let symbol = typeof assetOrSymbol === 'string' ? assetOrSymbol : assetOrSymbol.symbol
  // @TODO short term hack until BE can update
  if (symbol === 'Cash') symbol = 'USD'
  // @ts-ignore doesnt know how to refine on the kind check
  return symbol.toUpperCase() + ((type || assetOrSymbol.type) === AssetType.crypto ? '.X' : '')
}

export { formatSymbol }

const DayMs = 1000 * 60 * 60 * 24
const Days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
/***
 * Convert a dateString to a relative day:
 * today, yesterday, :dayOfWeek
 */
export function formatDayRelative(dateString: string) {
  let currentDayStart = new Date().setHours(0, 0, 0, 0)
  let referenceDate = new Date(dateString)
  // since the date is imported as UTC, we need to add the timezone offset to get the correct day back out
  referenceDate.setTime(referenceDate.getTime() + referenceDate.getTimezoneOffset() * 60 * 1000)
  let diffDays = (currentDayStart - referenceDate.getTime()) / DayMs
  if (diffDays < 1) return 'today'
  if (diffDays < 2) return 'yesterday'
  return 'on ' + Days[referenceDate.getDay()]
}

const SecMs = 1000 * 1
const MinMs = 1000 * 60
const HourMs = MinMs * 60
const WeekMs = DayMs * 7

const fullDateFmt = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })
const stringDateWoYearFmt = new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric' })
export function formatTimeRelative(dateString: string) {
  if (!dateString) return ''
  const referenceDate = new Date(dateString)
  const referenceDateMS = referenceDate.valueOf()
  const today = new Date()
  const diff = today.valueOf() - referenceDateMS
  // Return `01/01/2020` for not same year
  if (referenceDate.getFullYear() !== today.getFullYear()) return fullDateFmt.format(referenceDate)
  // Return `Month 1` format for more than 7 days
  if (diff > WeekMs) return stringDateWoYearFmt.format(referenceDate)
  if (diff > DayMs) return `${Math.floor(diff / DayMs)}d`
  if (diff > HourMs) return `${Math.floor(diff / HourMs)}h`
  if (diff > MinMs) return `${Math.floor(diff / MinMs)}m`
  if (diff > SecMs * 10) return `${Math.floor(diff / SecMs)}s`
  return 'Now'
}

const ordFormat = new Intl.PluralRules('en', { type: 'ordinal' })
const suffixes: { [key: string]: string } = {
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th'
}
export function formatOrdinal(number: number) {
  const suffix = suffixes[ordFormat.select(number)]
  return number + suffix
}

// Date string comes in as YYYY-MM-DD
export function formatOptionExpiration(dateString: string) {
  return DateTime.fromISO(dateString).toFormat('MM/dd/yy')
}

export function formatStrikePrice(strikePrice: number) {
  const currencyFmt = new Intl.NumberFormat('en', {
    ...(strikePrice % 1 === 0 ? intOptions : floatOptions),
    ...currencyOptions
  })
  return currencyFmt.format(strikePrice)
}

export function formatPercentPositionChange(changePercentage: number | undefined | null) {
  if (!isNumber(changePercentage)) return null
  const currencyFmt = new Intl.NumberFormat('en', {
    ...(changePercentage % 1 === 0 ? intOptions : floatOptions),
    ...percentOptions
  })
  return currencyFmt.format(changePercentage / 100)
}
