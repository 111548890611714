/* eslint-disable no-inner-declarations, no-undef, prefer-rest-params */

import { AuthTokensKey } from 'src/scopes/auth/constants'
import config from 'src/config'

if (typeof window !== 'undefined' && !localStorage.getItem(AuthTokensKey)) {
  /* ## BEGIN GA SNIPPET ## */
  window.dataLayer = window.dataLayer || []
  function gtag() {
    dataLayer.push(arguments)
  }
  gtag('js', new Date())
  gtag('config', config.publicGATrackingId)
  /* ## END GA SNIPPET ## */
}
