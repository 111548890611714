import { Apis } from '@commonstock/common/src/types/apis'

export enum Env {
  Local = 'local',
  Dev = 'dev',
  Staging = 'staging',
  Prod = 'prod'
}

type EnvConfig = {
  feedbackUuid: string
  auth0ClientId: string
  apiAuth0: string
  sendBirdAppId: string
  appLinkDomain: string
  mixpanelToken: string
  csUserUuid: string
  iOSAppId: string
  apiPaths: {
    [Apis.Gateway]: string
    [Apis.NotificationWS]: string
  }
  launchDarklyClientId: string
  originUrl: string
}
export type Config = EnvConfig & {
  csUsername: string
  whyDidYouRender: boolean
  apiMockApi: string
  apiGiphy: string
  giphyApiKey: string
  sentryDsn: string
  CS_ENV: Env
  enableMirage: boolean
  debugClient: boolean
  logTracking: boolean
  disableClientCache: boolean
  debugMirage: boolean
  flags: {
    emojiToImage: boolean
    darkMode: boolean
  }
  publicGATrackingId: string
}

let envConfig: EnvConfig
if (process.env.CS_ENV === Env.Local) {
  envConfig = {
    feedbackUuid: '@TODO',
    sendBirdAppId: '@TODO',
    auth0ClientId: '@TODO',
    apiAuth0: '@TODO',
    appLinkDomain: '@TODO',
    mixpanelToken: '4c62eafc007726bc70501fa17ef81d5a',
    csUserUuid: '',
    iOSAppId: 'HUXYC6W3CH.co.commonstock.CommonStockTestDev',
    apiPaths: {
      [Apis.Gateway]: 'https://localhost:3000',
      [Apis.NotificationWS]: 'http://localhost:3000'
    },
    launchDarklyClientId: '606d35e0d811720c0cb91aa0',
    originUrl: 'https://localhost:3000'
  }
} else if (process.env.CS_ENV === Env.Dev || process.env.CS_ENV === undefined) {
  envConfig = {
    feedbackUuid: '2fc3dcfb-fe81-4aaf-9384-2260c8eec6f4',
    sendBirdAppId: 'A59BF832-D584-4658-AFD0-E46BB54D427C',
    auth0ClientId: '5Nypvwy8AskUqAQ54MCIC2i8yHpqHPr2',
    apiAuth0: 'https://commonstock-dev.auth0.com',
    appLinkDomain: 'https://share-dev.commonstock.com',
    mixpanelToken: '4c62eafc007726bc70501fa17ef81d5a',
    csUserUuid: '50d9bc4f-b3cb-46b8-b3f9-c292e04ca39a',
    iOSAppId: 'HUXYC6W3CH.co.commonstock.CommonStockTestDev',
    apiPaths: {
      [Apis.Gateway]: 'https://api.dev.commonstock.com',
      [Apis.NotificationWS]: 'wss://api.dev.commonstock.com/realtime/notifications'
    },
    launchDarklyClientId: '606d35e0d811720c0cb91aa0',
    originUrl: 'https://commonstock.dev'
  }
} else if (process.env.CS_ENV === Env.Staging) {
  envConfig = {
    feedbackUuid: '42ce6446-ed86-4683-a5b1-caf4e19db22d',
    sendBirdAppId: '739400D0-37ED-476E-85C1-3A53DE2CEE21',
    auth0ClientId: '7PgnFV5vWWitKjZOEPjA9jWaoHKMREbp',
    apiAuth0: 'https://commonstock-staging.auth0.com',
    appLinkDomain: 'https://share-staging.commonstock.com',
    mixpanelToken: '4c62eafc007726bc70501fa17ef81d5a',
    csUserUuid: '5f9b2ace-dada-482b-ac67-70eb28beb8fd',
    iOSAppId: 'HUXYC6W3CH.co.commonstock.CommonStockTest',
    apiPaths: {
      [Apis.Gateway]: 'https://api.staging.commonstock.com',
      [Apis.NotificationWS]: 'wss://api.staging.commonstock.com/realtime/notifications'
    },
    launchDarklyClientId: '6063ed914689680c9eb3c8d1',
    originUrl: 'https://staging.commonstock.dev'
  }
} else if (process.env.CS_ENV === Env.Prod) {
  envConfig = {
    feedbackUuid: '8b03ea2a-8eb2-4ec4-a8d4-ab1eb59fdfb7',
    sendBirdAppId: '60B4F738-D12C-4EB0-9281-0184E724A753',
    auth0ClientId: 'JtJeb3fcot0LHduGPMQDXoDVUF0K1yQy',
    apiAuth0: 'https://commonstock-prod.auth0.com',
    appLinkDomain: 'https://share.commonstock.com',
    mixpanelToken: '1a327935d7c4749850292abb8af61a6c',
    csUserUuid: '8b03ea2a-8eb2-4ec4-a8d4-ab1eb59fdfb7',
    iOSAppId: 'HUXYC6W3CH.co.commonstock.Blackbird',
    apiPaths: {
      [Apis.Gateway]: 'https://gateway.commonstock.com',
      [Apis.NotificationWS]: 'wss://gateway.commonstock.com/realtime/notifications'
    },
    launchDarklyClientId: '6063ecf3f928440cc2ecef29',
    originUrl: 'https://commonstock.com'
  }
} else {
  throw new Error(`Invalid CS_ENV: ${process.env.CS_ENV}`)
}

const defaultConfig: Config = {
  ...envConfig,
  csUsername: 'commonstock',
  whyDidYouRender: false,
  apiMockApi: 'https://fake-api.com',
  apiGiphy: 'https://api.giphy.com',
  giphyApiKey: 'de3DfBMvmkyEt7N4sjYyT85DzfbEp22h',
  sentryDsn: 'https://839f322960d6466bb8503aa7fc848157@o106819.ingest.sentry.io/5532799',
  CS_ENV: process.env.CS_ENV as Env,
  enableMirage: false,
  debugClient: false,
  logTracking: false,
  disableClientCache: false,
  debugMirage: false,
  flags: {
    emojiToImage: true,
    darkMode: process.env.CS_ENV !== Env.Prod
  },
  publicGATrackingId: 'UA-170222567-1'
}

export default defaultConfig
